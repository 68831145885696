#loadingWidget.desktop-loadingWidget {
  #loadingWidgetProgress {
    position: absolute;
    top: 14px;
    left: 30px;
  }
  margin-top: 1rem;
  margin-right: 5.5rem;
  position: fixed;
  right: 0;
}

#loadingWidget.mobile-loadingWidget {
  #loadingWidgetProgress {
    position: absolute;
    top: 14px;
    left: 30px;
  }
  position: fixed;
  bottom: 50px;
  left: 50%;
  margin-left: -115px;
  z-index: 9000;
}
